import { LanguageSetup } from '../language-setup';
import { HttpRequestor } from '../http-requestor';
import { LocationTracker } from '../location-tracker';
import { Observable, interval, Subscription } from 'rxjs';
import { Const } from '../const';
import { UserStatusStrategyInterface } from './user-status.strategy.interface';

export class UserStatusPolling implements UserStatusStrategyInterface {
    private observer: Subscription;
    private callback: any;

    private userId: string;

    constructor(public langSetup: LanguageSetup,
        public httpReq: HttpRequestor,
        public locationTracker: LocationTracker
    ) {
        this.observer = null;
    }

    initialize(callback: any, userId: string) {
        this.callback = callback;
        this.userId = userId;
    }

    execute() {
        this.observer = interval(2000).subscribe(() => {
            /*
            this.httpReq.postDispatchConfirmationRequest(
                this.userId,
                this.langSetup.lang,
                this.statusReceiveCallback,
                this.locationTracker.getLat(),
                this.locationTracker.getLng(),
                this.locationTracker.getAccuracy()
            );
            */
           //FY22事前予約対応
            this.httpReq.postAdvanceReservationConfirmation(
                this.langSetup.lang,
                this.userId,
                '',
                this.statusReceiveCallback,
                this.locationTracker.getLat(),
                this.locationTracker.getLng(),
                this.locationTracker.getAccuracy()
        );
        });
    }

    statusReceiveCallback = (res, code, req_sts, auto) => {
        if (code !== Const.HTTP_REQUEST_CODE_SUCCESS || res === "") {
            throw new Error("postDispatchConfirmationRequest: HTTP Request Error.");
        }
        let json = res;
        if (json.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) { return; }
        // 予約をしていないユーザの場合、既存と同じ処理 (FY22事前予約対応)
        if(res.items.length == 0)
        {
            this.callback(res);
        }
        else
        {
            // 予約をしているユーザの場合、実行中予約フラグがtrueの予約をコールバック
            let mostNearlyArrayNumber = 0;

            for(let i = 0; i < res.items.length; i++)
            {
                if(res.items[i].activeReservationFlag == true)
                {
                    mostNearlyArrayNumber = i;
                    break;
                }
            }

            this.callback(res.items[mostNearlyArrayNumber]);       
        }
        
    };

    finalize() {
        if (this.observer == null) { return; }
        this.observer.unsubscribe();
        this.observer = null;
    }
}